import { combineReducers } from "redux";
import gameListReducer from "./gameListReducer";
import handlerReducer from "./handlerReducer";
import profileReducer from "./profileReducer";
import jackpotReducer from "./jackpotReducer";
import profileInfoReducer from "./profileInfoReducer";
import configReducer from "./configReducer";
import wordsReducer from "./wordsReducer";
import gameReducer from "./gameReducer";
import audioPlayerReducer from "./audioPlayerReducer";

const reducer = combineReducers({
  words: wordsReducer,
  config: configReducer,
  profile: profileReducer,
  jackpots: jackpotReducer,
  games: gameListReducer,
  gameCode: gameReducer,
  handling: handlerReducer,
  profileInfo: profileInfoReducer,
  audio: audioPlayerReducer,
});

export default reducer;
