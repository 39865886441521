import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getPopup } from 'redux/actions'

import { Button, Providers, TypeButtons, Monitor } from 'components'

import './Footer.scss'

export const Footer = () => {

	const dispatch = useDispatch()
	const profile = useSelector(state => state.profileInfo)
	const mobile = useSelector(state => state.handling.size.mobile)

	return (
		<footer className="footer">
			<TypeButtons/>
			<div className="container container_footer container_flex container_between">
				{ !mobile && 
					<div className="footer__balance">
							<Monitor key="balance-monitor" type='balance' info={true} value={ profile.cash } />
							<Monitor key="nonuce-monitor" type='bonus' info={true} value={ profile.cash_bonus} />
					</div>
				}
				<Providers />
				{ !mobile && 
					<div className="footer__controls">
						<div className="account" onClick={() => dispatch( getPopup({ active: true, name: 'account' }) )}>{ profile.login }</div>
						<div className="controls">
							<Button key="button_sound" type={["sound", "sq", "sq-adaptive"]} action={'sound'} />
							<Button key="button_lang" type={["lang", "sq", "sq-adaptive"]} action={'lang'} />
						</div>
					</div>
				}
			</div>
		</footer>
	)
}