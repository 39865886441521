import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { setProvidersList, setActiveGameList } from 'redux/actions'
import './ProvidersList.scss'

export const ProvidersList = () => {

	const dispatch = useDispatch()
	const providers = useSelector(state => state.games.providers)

	const handleClose = () => dispatch( setProvidersList(false) )

	return (
		<div className='providers-list' onClick={ handleClose }>
			<div className="wrapper">
			{ 
				providers.map( ({ label, name }) => ( 
					<div className="item" key={ label } onClick={ () => dispatch( setActiveGameList(label) ) }>
						<img src={ `./images/${label}.png` } alt={ name } />
					</div>
				)) 
			}
			</div>
		</div>
	)    
}
