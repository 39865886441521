import React from 'react'
import { useDispatch } from 'react-redux'

import { setActiveGameList } from 'redux/actions'

import './TypeButtons.scss'

export const TypeButtons = () => {
    
    const dispatch = useDispatch()

    return (
        <div className="type-buttons">
            <div className="type-buttons-container">
                <div className="type-button" onClick={ () => dispatch( setActiveGameList('all') ) }>ALL</div>
                <div className="type-button" onClick={ () => dispatch( setActiveGameList('top') ) }>TOP</div>
                <div className="type-button" onClick={ () => dispatch( setActiveGameList('new') ) }>NEW</div>
                <div className="type-button" onClick={ () => dispatch( setActiveGameList('last') ) }>Last games</div>
                <div className="type-button" onClick={ () => dispatch( setActiveGameList('favorite') ) }>Favorites</div>
                <div className="type-button" onClick={ () => dispatch( setActiveGameList('freespins') ) }>Freespins</div>
            </div>
        </div>
    )
}