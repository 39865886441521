import { GET_GAME_CODE } from "../actions/actionTypes";

const initialState = "";

const gameReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_GAME_CODE:
      //console.log("from reducer", action.payload);
      return action.payload;
    default:
      return state;
  }
};

export default gameReducer;
