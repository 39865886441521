import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { getPopup, getAuthForm, getAudioPlayer, setPreloader } from 'redux/actions'

import './Button.scss'

export const Button = ({ type, action, content = { btnText: '' } }) => {
    
    const dispatch = useDispatch()
    const paused = useSelector(state => state.audio.paused)
    const langBtn = useRef()

    useEffect(() => {
        action === 'lang' && document.getElementById('root').addEventListener("click", outsideClick)
        return () => { document.getElementById('root').removeEventListener("click", outsideClick) }
    }, [])
    
    const outsideClick = e => {
        if (langBtn.current && !langBtn.current.contains(e.target)) {
            langBtn.current.classList.remove('show');
        }
    }
    
    let classes = '';
    for (let i = 0; i < type.length; i++) {
        classes += `button_${type[i]} `;
    }

    const findAction = (action) => {
        //console.log('FINDING....',action);
        switch (action) {
            case 'logout':
                dispatch( setPreloader(true) )
                setTimeout(function () {
                    window.core.userLogout();
                }, 1000)
                break;
            case 'close':
                dispatch( getPopup({ active: false, name: '' }) )
                break;
            case 'closeGame':
                window.core.closeGame();
                break;
            case 'switchLogin':
                dispatch( getAuthForm(content.link) )
                break;
            case 'fullScreen':
                window.core.toggleFullScreen();
                break;
            case 'sound':
                dispatch( getAudioPlayer(!paused))
                break;
            default:
              return false;
          }
    }
    
    const langContent = () => {
        let lang = '';
        const showLang = (e) => {
            if (e.target.classList.contains('show')) { 
                e.target.classList.remove('show')
            }
            else {
                e.target.classList.add('show')
            }
        }
        const chooseLang = (a) => {
            if (a !== window.config.system.language) {
                window.core.setLanguage(a)
            }
            else {
                return
            }
            
        }
        if (window.config.languages.length > 1) {
            let langBtns = [];
            const activeLang = window.config.system.language;
            for (let i = 0; i < window.config.languages.length; i++){
                let active = activeLang === window.config.languages[i] ? 'active' : '';
                langBtns.push(
                    <div 
                        key={window.config.languages[i]}
                        className={`lang lang_${window.config.languages[i]} ${active}`}
                        onClick={()=>chooseLang(window.config.languages[i])}
                    >
                        {/* {window.config.languages[i]} */}
                    </div>
                )
            }
            return (
                <div ref={langBtn} className={`button ${classes}`} onClick={(e) => showLang(e)}>
                    {langBtns}
                </div>
            )
        }
        else {
            lang = window.config.languages[0]
            return (
                <div className={`button ${classes}`}>
                    <div className={`lang lang_${lang} active`}></div>
                </div>
            )
        }
    }

    if (action === 'lang') {        
        return langContent();
    } else if (action === 'sound') {
        return (
            <div className={`button ${classes}`} data-mute={paused} onClick={(e) => findAction(action, e)}>{ content.btnText }</div>
        )
    }
    else if ( action === 'logout') {        
        return (
            <div className={`button ${classes}`} onClick={(e) => findAction(action, e)}> <span>{ content.btnText }</span></div>
        )
    } else {
        return (
            <div className={`button ${classes}`} onClick={(e) => findAction(action, e)}>{ content.btnText }</div>
        )
    }
}