import React, { useEffect, useState, useRef, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation } from 'swiper'
import { setActiveGameList, setProvidersList } from 'redux/actions'

import './Providers.scss'

SwiperCore.use([ Navigation])

export const Providers = () => {

	const ref = useRef(null)
	const dispatch = useDispatch()

  	const providers = useSelector(state => state.games.providers)
	const activeGameList = useSelector(state => state.handling.activeGameList)
	const providersList = useSelector(state => state.handling.providersList)
	const mobile = useSelector(state => state.handling.size.mobile)

	const [swiper, setSwiper] = useState()
	const [externalChange, setExternalChange] = useState(true)
	const [firstChange, setFirstChange] = useState(false)

	const clickListener = useCallback(
			e => !ref.current.contains(e.target) && dispatch( setProvidersList(false))
		[ref.current])

	  useEffect(() => {
		document.getElementById('root').addEventListener('click', clickListener)
		return () => {
		  document.getElementById('root').removeEventListener('click', clickListener)
		}
	  }, [])

	  const params = {
		loop: true,
		slidesPerView: 1,
		slidesPerGroup: 1,
		setWrapperSize: true,
		nested: true,
		navigation: {
			prevEl: '.providers-prev',
			nextEl: '.providers-next'
		},
		onSlideChange: e => changeSlide(e),
		onSwiper: swiper => setSwiper(swiper)
	}

	const paramsList = {
		direction: 'vertical',
      slidesPerView: 'auto',
	  freeMode: true,
      scrollbar: {
		el: '.providers__view .swiper-scrollbar',
		draggable: true, 
		hide: true
	  },
	  mousewheel: true,
	}

	  useEffect(() => {
			if (swiper && externalChange) {
				const activeProvider = findSlideByLabel(activeGameList)
				activeProvider && swiper.slideTo(activeProvider)
			}
			
	  }, [activeGameList])


	  const hideImage = () => {
		if (mobile) {
			return 'image'
		} else {
			if (providersList) {
				return 'image hide'
			} else {
				return 'image'
			}
		}
	  }

  const slides = providers.map( (item, index) => (
		<SwiperSlide className={ `provider_${item.label}` } key={ index } id={ item.label }>
			<div className={ hideImage() } style={{backgroundImage: `url(./images/${item.label}.png)`}}>
				
			</div>
		</SwiperSlide>
	))

	useEffect(() => {
		if (!firstChange && slides && swiper && findSlideByLabel('all')) {
			swiper.slideTo(findSlideByLabel('all'))
			setFirstChange(true)
		}
		
  		}, [slides, swiper])
	
	const links = providers.map( (item, index) => (
		<div className="image" key={ index } onClick={ () => handleClick(item.label) } id={ item.label }>
			<img src={ `./images/${item.label}.png` } alt={ item.name } />
		</div>
	))
    
	const changeSlide = e => {
			setExternalChange(false)
			let storageLastProvider = window.localStorage.getItem('lastProvider')
			if (storageLastProvider !== null) {
				if ( window.localStorage.getItem('lastProvider') !== 'all') {
					dispatch( setActiveGameList(window.localStorage.getItem('lastProvider')) )
					window.localStorage.removeItem('lastProvider')
				} else {
					dispatch( setActiveGameList(window.localStorage.getItem('lastProvider')) )
					window.localStorage.removeItem('lastProvider')
				}
			} else {
				e.slides.map( (slide, index) => {
					if (slide.getAttribute('data-swiper-slide-index') == e.realIndex) {
						dispatch( setActiveGameList(slide.id) )
					}
				})
			}
			setExternalChange(true)
	}

	const findSlideByLabel = label => {
		let element = false
		swiper.slides.map( item => { 
			if (item.id === label) { 
				element = parseInt(item.getAttribute('data-swiper-slide-index'), 10) + 1
			}
		})
		
		return element
	}

	const handleOpen = () => dispatch( setProvidersList(true) )

	const handleClick = label => dispatch( setActiveGameList(label) )

	return (
		<div className="providers">

			<div className="providers-nav providers-prev"/>
			<div className="providers__container" onClick={ handleOpen }>
				<Swiper { ...params } ref={ref}>
					{ slides }
				</Swiper>
			</div>
      		<div className="providers-nav providers-next" />

			{ providersList && !mobile &&
				<div className='providers__popup'>
					<div className="providers__view">
							<Swiper {...paramsList}>
								<SwiperSlide>
									{ links }
								</SwiperSlide>
							
							</Swiper>
							<div className="swiper-scrollbar"></div>
					</div>
				</div>
			}

		</div>
	)
}