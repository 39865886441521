import React from 'react'
import { useSelector } from 'react-redux'

import './News.scss'

export const News = () => {

	const news = useSelector(state => state.profileInfo.news)
	const preloader = useSelector(state => state.handling.preloader)

	const initialDuration = 15

	const renderNews = news => {

		let items = news.length

		if (news.length > 0) return (
			<div className="list" style={ {animationDuration: `${initialDuration * items}s`} }>
				{
					news.map( (item, index) =>
						<span className="item" key={ index }>{ item }</span>
					) 
				}
			</div>
		)
	}

	return (
		<section className="news">
			<div className="wrapper" >
				{ !preloader && news && renderNews(news) }
			</div>
		</section>
	)
}