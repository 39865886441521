import React from 'react';
import './popup-bonuses.scss';
import { Button, Bonuses } from 'components'

const PopupBonuses = () => {

    return (
        <div className="popup popup_bonuses">
            <Button type={["close", "shadow", "sq", "sq-fixed"]} action={'closeGame'} />
            <Bonuses/>
        </div>
    )
}

export default PopupBonuses;
