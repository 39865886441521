import React, { useState } from 'react'
import './LoginForm.scss'

import { Input } from 'components'

import { useSelector, useDispatch } from 'react-redux';
import { getAuthorization, getAuthForm, setPreloader } from 'redux/actions'
import Spinner from '../../img/game-loader.gif'

export const LoginForm = ({ errorText }) => {

    const dispatch = useDispatch()

    const words = useSelector(state => state.words)
    const casino = useSelector(state => state.config.casino)
    const [formData, getFormData] = useState({});
    const [loading, setLoading] = useState(false)

    const login = (e) => {
        e.preventDefault();
        setLoading(true);
        setTimeout(function () {
            window.core.userLogin(formData.login, formData.password, 0, 0);
            if (window.config.system.authorized === true) {
                //console.log("Условие 2-1 авторизовано");
                dispatch( setPreloader(true) )
                setLoading(false);

                setTimeout(function () {
                    dispatch( getAuthorization(window.config.system.authorized) )
                }, 300)
                //console.log('AUTHORIZED');
              } else {
                setLoading(false);
                //console.log("Условие 2-2 не авторизовано");
                //console.log('NOT AUTHORIZED');
              }
        }, 200);
    }
    const getFormVal = (type, val) => {
        //console.log('FormData', formData);
        //console.log('from type', type);
        //console.log('from val', val);
        getFormData({
            ...formData,
            [type]: val    
        })
    }

    return (
        <form className="form" onSubmit={(e) => login(e)}>
            <Input inputType={"text"} valType={"login"} placeholder={words.server.lo_gin} getFormVal={getFormVal}/>
            <Input inputType={"password"} valType={"password"} placeholder={words.server.password} getFormVal={getFormVal}/>
            <button className={ loading ? "login-btn loading" : "login-btn"}>
                <img src={ Spinner } alt="Loader"/>
                <span>{ words.server.signin_button }</span>
            </button>
            <div className="form__error">{ errorText }</div>
            { casino && <div className="restore-link" onClick={() => dispatch( getAuthForm('forgot') )}>{words.server.forgot_password}</div>}
        </form>
    )
}