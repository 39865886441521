import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getJackpots, getPopup } from 'redux/actions'
import { Button, News } from 'components'

import FireWebm from 'img/fire.webm'
import FireMp4 from 'img/fire.mp4'

import './JackpotList.scss'

export const JackpotList = () => {

  const mobile = useSelector(state => state.handling.size.mobile)
  const login = useSelector(state => state.profileInfo.login)
  const jackpots = useSelector(state => state.jackpots)

  const dispatch = useDispatch()

  useEffect(() => {
    window.core.elements.jackpots.push({
      name: "index",
      mainElement: ".jackpots",
      element: ".jackpots .jackpot",
      separators: {
        thousands: ",",
        fractional: ".",
      },
      fractionalLength: 2,
      drawAll: info => dispatch( getJackpots(info) )
    })
                
  }, [])


  const renderElements = () => {

    const elements = []

    for (let jackpotName in jackpots) {
      elements.push(
        <div key={ jackpotName } className={ jackpots[jackpotName].activate ? "jackpot active" : "jackpot" }>
          { mobile && jackpots[jackpotName].activate  && 
              <video autoPlay={true} muted loop controls={false} playsInline>
                <source src={ FireWebm } type='video/webm' />
                <source src={ FireMp4 } type='video/mp4' />
              </video>
            }
          <div className={ `jackpot__name jackpot__name_${jackpotName}` } name={ jackpotName }></div>
          <div className="jackpot__val">{ jackpots[jackpotName].amountString }</div>
            
        </div>
      )
    }

    return elements.reverse()
  }

  return (
    <>
      { renderElements() }
      { mobile && 
        <div className="jackpot-list_mobile__bottom">
          <Button type={["sound", "shadow", "sq", "sq-fixed"]} action={'sound'} />
          <News />
          <div className="account" onClick={ () => dispatch( getPopup({ active: true, name: 'account' }) )}>
            { login }
          </div>
        </div>
      }
    </>
  )
}
