import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  getGameCode,
  getConfig,
  getGameList,
  getAuthorization,
  getProfileInfo,
  getPopup,
  getWords,
  getWindowSize,
  getFavoritGames,
  getHistoryGames,
} from 'redux/actions'

import { 
  FullScreen, 
  Header, 
  Sound, 
  Attention, 
  Footer, 
  Bonuses, 
  Button, 
  JackpotList, 
  Auth, 
  ProvidersList, 
  Games, 
  FooterAnimation, 
  Popup,
  Preloader
} from 'components'

import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper'

import './App.scss'
import 'swiper/swiper.scss'

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y])

const wordsFromServer = [
  'back',
  'last_games_is_empty',
  'please_add_games_to_favorite',
  'my_account',
  'confirm_phone',
  "real_money",
  "demo",
  "chargeable_balance",
  "or",
  "slots",
  "android_app",
  "windows_app",
  "enter_email",
  "enter_phone",
  "close",
  "full_screen",
  "no",
  "yes",
  "quit_the_game",
  "tv_games",
  "poker",
  "fish_games",
  "sport",
  "tournament",
  "casino",
  "dealers",
  "confirm_phone",
  "news",
  "deposit",
  "deposit_bonus_10",
  "wager",
  "terms_and_conditionse",
  "terms_and_conditions",
  "bonuses",
  "about_us",
  "responsible_gaming",
  "antispam_policy",
  "email_us",
  "instagram",
  "enjoy_quality",
  "show_more",
  "login",
  "registration",
  "login_social",
  "password",
  "forgot_password",
  "change_password",
  "confirm_password",
  "new_password",
  "old_password",
  "lo_gin",
  "log_in",
  "exit",
  "jackpots",
  "i_turned_18",
  "slots",
  "payment_system",
  "amount",
  "phone_num",
  "top",
  "new",
  "read_here",
  "promo",
  "signin_button",
  "confirm",
  "email",
  "fio",
  "providers",
  "search",
  "forgot_password",
  "send_link",
  "wrong_sign",
  "you_must_accept",
  "withdraw_success",
  "withdraw",
  "balance",
  "transaction_history",
  "games_history",
  "qr_code",
  "start",
  "the_end",
  "id",
  "сashback_bonus",
  "download_app",
  "wheel_of_fortune",
  "INFO",
  "the_end",
  "anti_spam_policy",
  "copyright_all_rights",
  'cumulative_bonus',
]
const wordsFromLocal = [
  "casino_is_operated",
  "productname",
  "site",
  "cashback_bonus",
  "cashback_bonus_description",
  "after_the_player",
  "all_registered_customers",
  "attention_confirm",
  "do_not_let",
  "do_not_attempt",
  "however_you_can",
  "if_you_would_like",
  "internet_gambling_can_be",
  "news_portfolio",
  "set_limits_on_how",
  "the_first_deposit",
  "the_terms_and_conditions",
  "try_to_determine",
  "you_can_set",
  'cashback_bonus',
  'cashback_bonus_description',
  'wager',
  'wager_description',
  'fortune_wheel_bonus',
  'fortune_wheel_bonus_description',
  'wheel_of_freespins',
  'wheel_of_freespins_description',
  'accumulated_bonus',
  'accumulated_bonus_description',
  'promocode_bonus',
  'promocode_bonus_description'
]

export const App = () => {

  const dispatch = useDispatch()
    
  const authorized = useSelector(state => state.profile.authorized)
  const popup = useSelector(state => state.handling.activePopup.active)
  const size = useSelector(state => state.handling.size)
  const game = useSelector(state => state.gameCode)
  const providersList = useSelector(state => state.handling.providersList)
  const jackpotsShow = useSelector(state => state.config.jackpotsShow)
  const linkBonus = useSelector(state => state.handling.linkBonus)
  const news = useSelector(state => state.profileInfo.news)

  const [swiper, setSwiper] = useState()

  useEffect(() => {
    // получаем конфиг в стейт
    dispatch( getConfig(window.config) )

    updateDimensions()
    // обработчик изменения размера сайта 
    window.addEventListener("resize", debounce(updateDimensions, 200))
    // получаем список слов
    dispatch ( getWords(window.core.getWords({
      server: wordsFromServer,
      local: wordsFromLocal,
      })
    ) )
    // получаем информацию профиля
    window.core.functions.reloadPage.push(function () {
      dispatch( 
        getProfileInfo({ ...window.core.terminal.content }) 
      )
    })
    // получаем список игр
    window.core.functions.drawGameList.push(function () {
      dispatch( getGameList(window.core.gameList) ) //получить список игр
      // получить игры для Favorite
      let favoriteList = localStorage.getItem("Favorite")
        ? localStorage.getItem("Favorite").split(",")
        : []
      dispatch( 
        getFavoritGames(
        {
          storage: favoriteList,
          gameList: window.core.gameList.gameList
        })
      )
      // получить игры для History
      dispatch( 
        getHistoryGames({
          history: getGameHistory(),
          gameList: window.core.gameList.gameList
        })
      )

    })
    // проверка авторизации
    dispatch( getAuthorization(window.config.system.authorized) )
    
    window.core.functions.userLogout = function () {
      dispatch( getAuthorization(window.config.system.authorized) )
      window.core.closeGame()
    }
    window.core.functions.openGame = function () {
      //console.log("openGame")
      dispatch( getGameCode(window.core.gameInfo.code) )
      dispatch( getPopup({ active: true, name: 'game' }) )
    }
    window.core.functions.closeGame = function () {
      //console.log("closeGame")
      dispatch( getGameCode('') )
      dispatch( getPopup({ active: false, name: '' }) ) 
      //получить игры для History
      dispatch(
        getHistoryGames({
          history: getGameHistory(),
          gameList: window.core.gameList.gameList
        })
      )
    }
    window.core.jackpotWinElem(".jackpotWin")
    return () => {
      window.removeEventListener("resize", updateDimensions)
    }
  }, [])

  function updateDimensions() {
    let vh = window.innerHeight * 0.01
  // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`)
    return dispatch(
      getWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    )
  }

  const debounce = (fn, ms) => {
    let timeout

    return function () {
      const fnCall = () => { fn.apply(this, arguments) }
      clearTimeout(timeout)
      timeout = setTimeout(fnCall, ms)
    }
  }

  function getMonth(a) {
    if (a >= 10) {
      return `${a}`
    } else {
      return `0${a}`
    }
  }

  function getGameHistory() {
    const date = new Date()
    const year = date.getFullYear()
    const month = getMonth(date.getMonth() + 1)
    const day = date.getDate()
    
    let response = window.core.query({
      cmd: "casinoGamesHistory",
      start: `${year}-${month}-${day} 00:00:00`,
      end: window.core.terminal
        ? window.core.terminal.datetime
        : `${year}-${month}-${day} 00:00:00`,
    })
    let history = []
    if (response.content) {
      response.content.map((item) => {
        if (!(history.indexOf(item.game_name) + 1)) {
          history.push(item.game_name)
        }
      })
    }
    return history
  }

    const checkGameFrame = () => {
      if ( ((jackpotsShow && jackpotsShow.launcher) || !size.landscape) && game) {
        return true
      } else {
        return false
      }
    }

    useEffect(() => {
      const body = window.document.getElementsByTagName('body')
      
      size.mobile && popup ? body[0].style.overflow = "hidden" : body[0].style.overflow = "visibleт"
    }, [popup])

    useEffect(() => {
      linkBonus && swiper && swiper.slideTo(2)
    }, [linkBonus])

  return (
    <div className="view">
      <Preloader/>
      { !authorized && <Auth/> }
      { authorized && <Header /> } 
      
      
      { !size.mobile && authorized && 
        <div className="content">
          <main>
            { !size.landscape &&
                <div className="jackpot-list_desktop">
                  <JackpotList />
                </div>
            }
            { checkGameFrame() ? 
            
                <div className="game-frame" style={ !size.mobile ? ({paddingTop: news.length > 0 ? 6 + 'vw' : 5 + 'vw'}): {} }>
                  { }
                  <div className="inner-game-frame" dangerouslySetInnerHTML={{__html: game}}></div>

                  { !size.landscape && 
                  <> 
                    <Button type={["close", "shadow", "sq", "sq-fixed"]} action={'closeGame'} />
                    <FooterAnimation/>
                  </>
                  }
                  
                </div>
              : 
                <Games /> 
            }
          </main>
          
          <div className={checkGameFrame() ? 'hidden-footer' : ''}>
            
            <FooterAnimation/>
            <Footer/>
          </div>

        </div>
      }

      {size.mobile && authorized && <div className="content" style={{width: size.width, height: size.height}}>
        <Swiper
          width={size.width}
          height={size.height - 55}
          key="main-slider"
          setWrapperSize="true"
          slidesPerView={ 1 }
          initialSlide={ window.localStorage.getItem('lastMobileScreen') !== null ? window.localStorage.getItem('lastMobileScreen') : 0 }
          onSlideChange={ swiper => window.localStorage.setItem('lastMobileScreen', swiper.activeIndex)}
          direction={'vertical'}
          onSwiper={ swiper => setSwiper(swiper) }
          className="main-slider"
          pagination={{ clickable: true }}
        >
          <SwiperSlide>
            <div className="jackpot-list_mobile" style={{ height: size.height - 55}}>
              <JackpotList />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <main>
              <Games />
            </main>
              <FooterAnimation/>
            
            <Footer/>
          </SwiperSlide>
          <SwiperSlide>
            <Bonuses />
          </SwiperSlide>
        </Swiper>
      </div>}

      { size.mobile && <FullScreen /> }
      { providersList && size.mobile && <ProvidersList/> }
      { popup && <Popup/> }
      
      <div className="jackpotWin"></div>

      <div className="wheel"></div>

      <Sound />
      <Attention />
    
    </div>
  )
}