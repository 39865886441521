import React, { useState, useEffect } from 'react'
import './Bonuses.scss'
import { useSelector } from 'react-redux'
import { Bonus } from 'components'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation, Mousewheel } from 'swiper'

import Wager from 'img/wager.png'
import WagerLandscape from 'img/wager-landscape.png'

import CashbackBonus from 'img/cashback_bonus.png'
import CashbackBonusLandscape from 'img/cashback_bonus-landscape.png'

import FortuneWheelBonus from 'img/fortune_wheel_bonus.png'
import FortuneWheelBonusLandscape from 'img/fortune_wheel_bonus-landscape.png'

import AccumulatedBonus from 'img/accumulated_bonus.png'
import AccumulatedBonusLandscape from 'img/accumulated_bonus-landscape.png'

import WheelOfFreespins from 'img/wheel_of_freespins.png'
import WheelOfFreespinsLandscape from 'img/wheel_of_freespins-landscape.png'

import PromocodeBonus from 'img/promocode_bonus.png'
import PromocodeBonusLandscape from 'img/promocode_bonus-landscape.png'

SwiperCore.use([Navigation, Mousewheel])

export const Bonuses = () => {

	const words = useSelector(state => state.words)
	const linkBonus = useSelector(state => state.handling.linkBonus)
	const windowSize = useSelector(state => state.handling.size)
	const [swiper, setSwiper] = useState(false)

	const bonuses = [
		{
			id: 'wager',
			title: words.local.wager, 
			text: words.local.the_first_deposit,
			images: [Wager, WagerLandscape],
		},
		{
			id: 'cashback_bonus',
			title: words.local.cashback_bonus, 
			text: words.local.cashback_bonus_description,
			images: [CashbackBonus, CashbackBonusLandscape]
		},
		{
			id: 'fortune_wheel_bonus',
			title: words.local.fortune_wheel_bonus, 
			text:  words.local.fortune_wheel_bonus_description,
			images: [FortuneWheelBonus, FortuneWheelBonusLandscape]
		},
		{
			id: 'accumulated_bonus',
			title: words.local.accumulated_bonus, 
			text:  words.local.accumulated_bonus_description,
			images: [AccumulatedBonus, AccumulatedBonusLandscape]
		},
		{
			id: 'wheel_of_freespins',
			title: words.local.wheel_of_freespins, 
			text:  words.local.wheel_of_freespins_description,
			images: [WheelOfFreespins, WheelOfFreespinsLandscape]
		},
		{
			id: 'promocode_bonus',
			title: words.local.promocode_bonus, 
			text:  words.local.promocode_bonus_description,
			images: [PromocodeBonus, PromocodeBonusLandscape]
		}
		//accumulated_bonus: [words.local.accumulated_bonus, words.local.accumulated_bonus_description],
		//happy_hour_bonus: [words.local.happy_hour_bonus, words.local.happy_hour_bonus_description],
		//happy_hour_bonus_second: [words.local.happy_hour_bonus_second, words.local.happy_hour_bonus_second_description],
		//wheel_of_freespins: [words.local.wheel_of_freespins, words.local.wheel_of_freespins_description],
		//promocode_bonus: [words.local.promocode_bonus, words.local.promocode_bonus_description],
	]

    const renderSlides = () => (
			bonuses.map( bonus => {
			if (window.config.bonusesInBanner[bonus.id]) {
				return (
					<SwiperSlide key={ 'bonus-slide-' + bonus.id } id={ bonus.id }>
						<Bonus title={ bonus.title } images={ bonus.images } text={ bonus.text } id= { bonus.id } />
					</SwiperSlide>
				)
			}
		}) )

		useEffect(() => {
			if (swiper) {
					swiper.lazy.load()
			}
		}, [windowSize.landscape])

		useEffect(() => {
			linkBonus && swiper && swiper.slideTo(findSlideById(linkBonus))
		  }, [linkBonus])


		const findSlideById = id => {
		let element = false
		swiper.slides.map( (item, index) => { 
			if (item.id === id) { 
				element = index
			}
		})
		
		return element
		}

		
		if (!windowSize.mobile) {
			return (
				<div className="bonuses bonuses-popup">
					<Swiper
						key="game-slider"
						slidesPerView={1}
						navigation
						preloadImages={false}
						lazy
						pagination={{ clickable: true }}
						centerInsufficientSlides
						updateOnWindowResize
						mousewheel
					>
						{ renderSlides() }
					</Swiper>
				</div>
			)
		} else {
			return (
				<div className="bonuses">
					<Swiper
						key="game-slider"
						slidesPerView={1}
						width={windowSize.width}
						height={windowSize.height - 55}
						navigation
						preloadImages={false}
						lazy
						pagination={{ clickable: true }}
						centerInsufficientSlides
						updateOnWindowResize
						mousewheel
						onSwiper={swiper => setSwiper(swiper)}
					>
					{ renderSlides() }
					</Swiper>
				</div>
			)
		}
}