import React from 'react';
import './password.scss';
import { useSelector } from 'react-redux';

import { Input } from 'components'

const ChangePassword = () => {
  
  const words = useSelector(state => state.words)

  const [formData, getFormData] = React.useState({
    confirmPassword: "",
    newPassword: "",
    oldPassword: "",
  });
  const [message, setMessage] = React.useState('');
  const [messageType, setMessageType] = React.useState('');
  
  const getFormVal = (type, val) => {
    //console.log('FormData', formData);
    //console.log('from type', type);
    //console.log('from val', val);
    getFormData({
        ...formData,
        [type]: val    
    })
  }

  function changePassword(data) {
    //console.log('formData', formData);

    if (data.confirmPassword === '' || data.newPassword === '' || data.oldPassword === '') {
      setMessage("not_all_data_entered");      
      setMessageType("error");
      return false;
    }
    else if (
      data.confirmPassword !== data.newPassword
    ) {
      setMessage("passwords_not_match");      
      setMessageType("error");
      return false;
    }
    let response = window.core.query({
      cmd: "casinoPasswordChange",
      password: data.oldPassword,
      passwordNew: data.newPassword,
    });
    if (response.status === "success") {
      setMessage("password_change_succesfull");
      setMessageType("success");
    } else {
      setMessage(response.error);
      setMessageType("error");
    }
    return false;
  }

    return (
    <div className="account__tab account__tab-password">
      <div className="row">
            <label className="title">
            {words.server.password}
            </label>
            <div className="value">
              <Input
                inputType={"password"}
                valType={"oldPassword"}
                getFormVal={getFormVal}
              />
            </div>
          </div>
      <div className="row">
              <label className="title">
              {words.server.new_password}
              </label>
              <div className="value">
                <Input
                  inputType={"password"}
                  valType={"newPassword"}
                  getFormVal={getFormVal}
                />
              </div>
            </div>
      <div className="row">
              <div className="title">{words.server.confirm_password}</div>
              <label className="value">
                <Input
                  inputType={"password"}
                  valType={"confirmPassword"}
                  getFormVal={getFormVal}
                />
              </label>
            </div>
      <div className="edit change-passeord"
      onClick={()=>changePassword(formData)}
      >
        Ok
      </div>
        <div className={`message message_${messageType}`}>{message}</div>         
    </div>
    )
}  
  
export default ChangePassword;