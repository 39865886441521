import React from 'react'
import './Attention.scss'

export const Attention = () => (
	<div className="user-bonuses-attention">
		<div className="user-bonuses-title">
			<p className="user-attention">Attention! <br/> If you make one bet in this game <br/> bonuses canceled:</p>
			<p className="user-alert"></p>
		</div>
		<br/>

		<div className="user-bonuses-list">
			<ul>
				<li className="cash-bonus">Cashback <span className="bonus-value"></span></li>
				
				<li className="wager-bonus">Wager <span className="bonus-value"></span></li>
				<li className="wager-bonus2">Wager <span className="bonus-value"></span></li>
			
			</ul>
		</div>
		<br/>

		<div className="user-bonuses-buttons-wrap">
			<button className="user-bonuses-button user-bonuses-button-continue">
				<p>Continue</p>
			</button>

			<button className="user-bonuses-button user-bonuses-button-cancel">
				<p>Cancel</p>
			</button>

			<button className="user-bonuses-button user-bonuses-button-close">
				<p>Close</p>
			</button>
		</div>
  </div>
)