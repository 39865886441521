import React, { useRef, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getFavoritGames, setPreloader } from 'redux/actions'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation, Mousewheel, Lazy, Virtual } from 'swiper'

import './Games.scss'

SwiperCore.use([Navigation,Mousewheel, Lazy, Virtual])

export const Games = () => {

    const dispatch = useDispatch()
    const slider = useRef()
    
    const size = useSelector(state => state.handling.size)
    const activeGameList = useSelector(state => state.games.gamesByProviders[state.handling.activeGameList])
    const activeProvider = useSelector(state => state.handling.activeGameList)
    const allGames = useSelector(state => state.games.gameListAll)
    const demoForGames = useSelector(state => state.config.demoForGames)
    const words = useSelector(state => state.words)
    const news = useSelector(state => state.profileInfo.news)

    const [swiper, setSwiper] = useState()
    const [separator, setSeparator] = useState(4)
    const [count, setCount] = useState(0)
        
    useEffect(() => {
        if (swiper) {
            swiper.update()
            swiper.updateSlides()
            swiper.updateSize()
            swiper.scrollbar.updateSize()
            swiper.pagination.update()
            swiper.lazy.load()
            swiper.virtual.update()
        }

        if (size.mobile) {
            if (size.landscape) {
                setSeparator(Math.floor(slider.current.offsetHeight / 110))
            } else {
                setSeparator(Math.floor(slider.current.offsetHeight / 150))
            }
        } else {
            if (size.landscape) {
                setSeparator(Math.floor(slider.current.offsetHeight / (size.width / 100 * 11.2)))
            } else {
                setSeparator(Math.floor(slider.current.offsetHeight / (size.width / 100 * 16)))
            }
        }
    }, [size])


    useEffect(() => {
       
        if (swiper) {
            setCount(count + 1)
            if (window.localStorage.getItem('lastSlide') !== null) {
                let slide = parseInt(window.localStorage.getItem('lastSlide'), 10)
                swiper.slideTo(slide)
                if (count !== 0) {
                    swiper.scrollbar.updateSize()
                    swiper.update()
                    swiper.updateSlides()
                    swiper.pagination.update()
                    swiper.updateSize()
                    window.localStorage.removeItem('lastSlide')
                }
            } else {
                swiper.slideTo(0)
            }
            
            swiper.lazy.load()
            swiper.virtual.update()
        }
    }, [activeGameList])

    useEffect(() => {
        dispatch( setPreloader(true) )
    }, [])   
    
    
    
    const setFavorite = function (e) {

        const gameID = e.target.id;
        if (
        e.target &&
        e.target.classList.contains("favorite") &&
        e.target.classList.contains("disabled")
        ) {
        e.target.classList.remove("disabled");
        let favoriteList = localStorage.getItem("Favorite")
            ? localStorage.getItem("Favorite").split(",")
            : [];
        favoriteList.push(gameID);

        favoriteList = favoriteList.join();
            localStorage.setItem("Favorite", favoriteList);
        dispatch( getFavoritGames({ storage: favoriteList.split(","), gameList: allGames }) ) 
        } else if (
        e.target &&
        e.target.classList.contains("favorite") &&
        !e.target.classList.contains("disabled")
        ) {
        e.target.classList.add("disabled");
        const favoriteList = localStorage.getItem("Favorite").split(",");
        let newFavoriteList = favoriteList.map((item) => {
            if (item !== gameID) {
            return item;
            }
        });
        newFavoriteList = newFavoriteList.join();
        localStorage.setItem("Favorite", newFavoriteList);
        dispatch( getFavoritGames({ storage: newFavoriteList.split(","), gameList: allGames }) )
        }
    };

    const getFavorite = (id) => {
        if (localStorage.getItem("Favorite")) {
        const favoriteList = localStorage.getItem("Favorite").split(",");
        const result = favoriteList.filter((elem) => elem === id);
        return result.length > 0;
        } else return 0;
    };


    
    const sliceArr = (a, b, c = []) => {
        if (b === 0) {
            b = 1;
        }
        let slicedArr = a.slice(b);
        let newArr = c;
        newArr.push(a.slice(0, b))
        if (slicedArr.length >= b) {
            sliceArr(slicedArr, b, newArr)
        }
        else {
            newArr.push(slicedArr)
        }
        return newArr
    }

    const renderSlideStyle = () => {
        
        if (size.mobile) {
            if (!size.landscape) {
                return { height: separator * 150 }
            } else {
                return { height: separator * 110 }
            }
        } else {
            const rows = '1fr '.repeat(separator)
            return { gridTemplateRows: rows }
        }
    }

    window.onunload = window.onbeforeunload = function () {
        window.localStorage.setItem('lastProvider', activeProvider)
        window.localStorage.setItem('lastSlide', swiper.snapIndex)
    }

    const handleOpenGame = (id, label, a, b) => {
        window.localStorage.setItem('lastProvider', activeProvider)
        window.localStorage.setItem('lastSlide', swiper.snapIndex)
        window.core.openGame(id, label, a, b)
        
    }

    const separate = (a = [], b) => {

        const old = 'game_img'

        const newArr = []
        if (b === 0) {
            b = 1;
        }
        const slicedArr = sliceArr(a, b)
        if (a !== undefined) {
            for (let i = 0; i < Math.ceil(a.length / b); i++) {

                if (slicedArr[i] !== undefined) {
                    const slide = slicedArr[i].map(({ id, img, name, label, fs, demo }) => {

                        const newImg = img.replace(old, 'game_img_4')
                        return (
                            <div key={`${label}_${id}`} id={id} className="game">
                                { !size.mobile && <div className="game-inner" onClick={() => handleOpenGame(id, label, 1, 0)}>
                                    <img height={(size.width > 992) && (size.height > 568)?100:144} data-src={newImg} className="swiper-lazy" />
                                </div>}
                                { size.mobile && <img data-src={newImg} className="swiper-lazy" />}

                                { demoForGames ?
                                    <div className="game__content">
                                        <div className="game__name">{name}</div>
                                        <div className="game__button game__button-play" onClick={() => handleOpenGame(id, label, 1, 0)}></div>
                                        {demo !== '0' && <div className="game__button game__button-demo" onClick={() => handleOpenGame(id, label, 1, 1)}></div>}
                                    </div>
                                    :
                                    <div className="game__content">
                                        <div className="game__name">{name}</div>
                                        <div className="game__button game__button-play" onClick={() => handleOpenGame(id, label, 1, 0)}></div>
                                    </div>
                                }
                                {fs && <div className="freespin"></div>}
                                <div onClick={setFavorite} id={id} className={getFavorite(id) ? "favorite" : "favorite disabled"}></div>
                                <div className="border"></div>
                            </div>
                        )
                    })

                    newArr.push(
                        <SwiperSlide key={`slide_${i}`} virtualIndex={i}>
                            <div className="inner-slide" style={ renderSlideStyle() }>
                                {slide}
                            </div>
                        </SwiperSlide>
                    )
                }
            }
        }

        if (newArr.length > 0) {
            return newArr
        } else {
            let emptyText = ''

            if (activeProvider === 'favorite') {
                emptyText = words.server.please_add_games_to_favorite
            } else if (activeProvider === 'last') {
                emptyText = words.server.last_games_is_empty
            }
            return (
                <SwiperSlide key={`empty`} virtualIndex={0}>
                    <p className="empty">
                        { emptyText }
                    </p>
                </SwiperSlide>
            )
        }
    }

    let params = {
        preloadImages: false,
        key: "game-slider",
        slidesPerView: 4,
        slidesPerGroup: 1,
        setWrapperSize: true,
        centerInsufficientSlides: true,
        mousewheel: true,
        pagination: {
            el: '.fractions',
            type: 'fraction',
        },
        roundLengths: true,
        watchSlidesProgress: true,
        watchSlidesVisibility: true,
        updateOnWindowResize: true,
        scrollbar: {
            draggable: true,
            el: '.swiper-scrollbar'
        },
        navigation: {
			prevEl: '.gameList-prev',
			nextEl: '.gameList-next'
		},
        virtual: {
            addSlidesAfter: 2,
            addSlidesBefore: 2,
            cache: true
        },
        lazy: {
            loadPrevNext: true,
            loadPrevNextAmount: 4,
            loadOnTransitionStart: false
        },
        breakpoints: {
            320: {
                slidesPerView: 2
            },
            480: {
                slidesPerView: Math.floor(!size.landscape ? size.height / 130 : size.width / 130)
            },
            991: {
              slidesPerView: 4,
            }
        },
        onSwiper: swiper => {
            setSwiper(swiper)
            !size.mobile && swiper.lazy.loadInSlide(swiper.activeIndex + 4)
        },
        onLazyImageReady: () => {
            setTimeout(function () {
                dispatch( setPreloader(false) )
            }, 1000)
            if (activeProvider === 'all') window.localStorage.removeItem('lastSlide')
            
        },
        onSlideChange: swiper => { !size.mobile && swiper.lazy.loadInSlide(swiper.activeIndex + 4) },
        onBeforeTransitionStart: swiper => !size.mobile && setWillChange(swiper, true),
        onTransitionEnd: swiper => !size.mobile && setWillChange(swiper, false)
    }

    const setWillChange = (target, value) => {
        value ? target.wrapperEl.style.willChange = 'transform' : target.wrapperEl.style.willChange = 'auto'
    }
    return (
        <div className="gameList" style={ !size.mobile ? { marginTop: news && (news.length > 0 ? 6 + 'vw' : 5 + 'vw')} : {} } ref={slider}>
            <div className="gameList-nav gameList-prev"/>
            <Swiper {...params}>
                { separate(activeGameList, separator) }
                
            </Swiper>
            <div className="gameList-nav gameList-next"/>
            <div className="swiper-scrollbar"></div>
            <div className="fractions"></div>
            
        </div>
    )
}