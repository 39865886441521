import React,{useEffect} from 'react'
import './popup-sms.scss'
import { Button, Input } from 'components'

const PopupSms = () => {
    const [formData, getFormData] = React.useState({});
    useEffect(() => {

    },[])

    const getFormVal = (type, val) => {
        //console.log('type',type);
        //console.log('val',val);
          getFormData({
              ...formData,
              [type]: val    
          })
    }

    return (
        <div className="popup popup_sms">
            <Button type={["close", "shadow", "sq", "sq-fixed"]} action={'close'} />
            <Input inputType={"mail"} valType={"email"} placeholder="E-mail" getFormVal={getFormVal} />
        </div>
    )
}

export default PopupSms;
