import React, { useState } from 'react'
import './Input.scss'


export const Input = ({ inputType, valType, placeholder, getFormVal }) => {
    
	const [inputVal, setInputVal] = useState('')

	const updateInputVal = e => {
		setInputVal(e.target.value)
		getFormVal(valType, e.target.value)
	}
	return (
		<input
				key={ valType }
				className="input"
				type={ inputType }
				placeholder={ placeholder }
				onChange={ (e) => updateInputVal(e) }
				value={ inputVal }
		/>
	)
}