import React from 'react'
import { useSelector } from 'react-redux'
import CompoundTimer from 'react-compound-timer'

import './Timer.scss'

export const Timer = () => {

  const wagerTime = useSelector(state => state.profileInfo.wager.left)

  return (
    <div className="timer-container">
      <div className="timer-container-value">

          <div className="timer__title">wager timer:</div>

          { wagerTime === 0 && <div className="timer timer__now">NOW!</div> }

          { wagerTime !== 0 && 
            <div className="timer">
              <CompoundTimer
                initialTime={ wagerTime * 1000 }
                direction="backward"
                formatValue={(value) => `${(value < 10 ? `0${value}` : value)}`}
              >
                {() => (
                    <>
                        <CompoundTimer.Hours />:
                        <CompoundTimer.Minutes />:
                        <CompoundTimer.Seconds />
                    </>
                )}
              </CompoundTimer>
            </div> 
          }
      </div>
    </div>
  )
}