import React, {useState ,useEffect} from 'react';
import './qrcode.scss';


const QrCode = () => {
    const [qrCode, setQrcode] = useState('')
    useEffect(() => {
        setQrcode(window.core.query({ qrcode: window.core.terminal.content.id }).qrcode)
    },[])

    return (
        <div className="account__tab account__tab-qrcode">
            <div className="code">
                <img src={qrCode} alt="qrcode" />
            </div>
        </div>
    )
}


  
  
export default QrCode;