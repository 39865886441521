import { GET_CONFIG } from "../actions/actionTypes";

const initialState = {};

const configReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CONFIG:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default configReducer;
