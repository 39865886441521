import React, { useState } from 'react';

import './Popup.scss';

import { useSelector } from 'react-redux'

import { Button } from 'components'
import Balance from 'components/profile/balance';
import ChangePassword from 'components/profile/password-change';
import History from 'components/profile/game-history';
import QrCode from 'components/profile/qr-code';

import PopupInfo from './popup-info';
import PopupSms from './popup-sms';
import PopupBonuses from './popup-bonuses';

import Logo from 'img/logo.png';

export const Popup = () => {

    const type = useSelector(state => state.handling.activePopup.name)
    const words = useSelector(state => state.words)
    const size = useSelector(state => state.handling.size)
    const game = useSelector(state => state.gameCode)
    const launcher = useSelector(state => state.config.jackpotsShow.launcher)

    const [activeTab, setActiveTab] = useState('balance');
    
    const changeTab = type => setActiveTab(type)

    const account = (
        <div className="popup popup_fullscreen">
            <div className="popup-top">
                <div className="popup-logo">
                    <img src={ Logo } alt="pharaon-logo"/>
                </div>
                <div className="popup-title">{words.server.my_account}</div>
                {!size.mobile && <div className="controls">
                    <Button key="button_sound_popup" type={["sound", "shadow", "sq", "sq-fixed"]} action={'sound'} />
                    <Button key="button_lang_popup" type={["lang", "sq", "sq-fixed"]} action={'lang'}/>
                    <Button type={["logout", "shadow", "wide"]} action={'logout'} content={{btnText: words.server.exit}}/>
                    <Button key="button_close_popup" type={["close", "shadow", "sq", "sq-fixed"]} action={'close'} />
                    
                </div>}
            </div>
            <div className="popup__content">
                {size.mobile && <div className="account__controls">
                    <div className="account__controls-group">
                        <Button type={["sound", "shadow", "sq", "sq-fixed"]} action={'sound'} />
                        <Button type={["lang", "sq", "sq-fixed"]} action={'lang'}/>
                        <Button type={["logout", "shadow", "wide"]} action={'logout'} content={{btnText: words.server.exit}} />
                    </div>
                    
                    <Button type={["close", "shadow", "sq", "sq-fixed"]} action={'close'} />
                    
                </div>}
                <div className="account__tabLinks">
                    <div
                        className={activeTab === 'balance' ? "account__tablink active" : "account__tablink"}
                        onClick={()=>changeTab('balance')}
                    >{words.server.balance}</div>
                    <div
                        className={activeTab === 'password' ? "account__tablink active" : "account__tablink"}
                        onClick={()=>changeTab('password')}
                    >{words.server.change_password}</div>
                    <div
                        className={activeTab === 'history' ? "account__tablink active" : "account__tablink"}
                        onClick={()=>changeTab('history')}
                    >{words.server.games_history}</div>
                    <div
                        className={activeTab === 'code' ? "account__tablink active" : "account__tablink"}
                        onClick={()=>changeTab('code')}
                    >{words.server.qr_code}</div>
                </div>
                
                <div className="account__tabs">
                    {activeTab === 'balance' && <Balance />}
                    {activeTab === 'password' && <ChangePassword />}
                    {activeTab === 'history' && <History />}
                    {activeTab === 'code' && <QrCode />}
                    <div className="account__tab-bottom">
                        <div className="app-links">  
                            <div className="title">{words.server.download_app}</div>    
                            <a className="download-link" href="#">
                                <div className="image">
                                    <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="39.008"
                                    height="44.582"
                                    viewBox="0 0 39.008 44.582"
                                >
                                    <g
                                    id="Сгруппировать_2679"
                                    data-name="Сгруппировать 2679"
                                    transform="translate(0 0)"
                                    >
                                    <path
                                        id="Контур_8817"
                                        data-name="Контур 8817"
                                        d="M34.79,192.007A2.787,2.787,0,0,0,32,194.793v11.145a2.786,2.786,0,0,0,5.573,0V194.793A2.787,2.787,0,0,0,34.79,192.007Z"
                                        transform="translate(-32.004 -175.288)"
                                        fill="#9ade4c"
                                    />
                                    <path
                                        id="Контур_8818"
                                        data-name="Контур 8818"
                                        d="M418.79,192.007A2.787,2.787,0,0,0,416,194.793v11.145a2.786,2.786,0,0,0,5.573,0V194.793A2.787,2.787,0,0,0,418.79,192.007Z"
                                        transform="translate(-382.568 -175.288)"
                                        fill="#9ade4c"
                                    />
                                    <path
                                        id="Контур_8819"
                                        data-name="Контур 8819"
                                        d="M128,210.581a2.787,2.787,0,0,0,2.786,2.786v5.411a2.786,2.786,0,0,0,5.573,0v-5.411h5.573v5.411a2.786,2.786,0,1,0,5.573,0v-5.411a2.787,2.787,0,0,0,2.786-2.786V193.863H128Z"
                                        transform="translate(-119.645 -176.983)"
                                        fill="#9ade4c"
                                    />
                                    <path
                                        id="Контур_8820"
                                        data-name="Контур 8820"
                                        d="M146.1,5.286l2.48-3.006A1.393,1.393,0,0,0,146.436.5L143.724,3.79a10.9,10.9,0,0,0-9.206.033L131.88.524A1.394,1.394,0,0,0,129.7,2.266l2.446,3.059A11.069,11.069,0,0,0,128,13.932h22.291A11.076,11.076,0,0,0,146.1,5.286Zm-9.741,4.466a1.393,1.393,0,1,1,1.393-1.393A1.394,1.394,0,0,1,136.363,9.753Zm5.573,0a1.393,1.393,0,1,1,1.393-1.393A1.394,1.394,0,0,1,141.936,9.753Z"
                                        transform="translate(-119.645 0)"
                                        fill="#9ade4c"
                                    />
                                    </g>
                                </svg>
                                </div>
                                <div className="text">Android</div>
                            </a>
                            <a className="download-link" href="#">
                                <div className="image">
                                    <svg
                                    id="Сгруппировать_3885"
                                    data-name="Сгруппировать 3885"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="37.799"
                                    height="35.436"
                                    viewBox="0 0 37.799 35.436"
                                >
                                    <path
                                    id="Контур_8821"
                                    data-name="Контур 8821"
                                    d="M0,54.067V65.879H16.537V52Z"
                                    transform="translate(0 -49.342)"
                                    fill="#fff"
                                    />
                                    <path
                                    id="Контур_8822"
                                    data-name="Контур 8822"
                                    d="M256,18.362V32.537h18.9V16Z"
                                    transform="translate(-237.101 -16)"
                                    fill="#fff"
                                    />
                                    <path
                                    id="Контур_8823"
                                    data-name="Контур 8823"
                                    d="M256,272v14.175l18.9,2.362V272Z"
                                    transform="translate(-237.101 -253.101)"
                                    fill="#fff"
                                    />
                                    <path
                                    id="Контур_8824"
                                    data-name="Контур 8824"
                                    d="M0,272v11.812l16.537,2.067V272Z"
                                    transform="translate(0 -253.101)"
                                    fill="#fff"
                                    />
                                </svg>
                                </div>
                                <div className="text">Windows</div>
                            </a>
                        </div>    
            
                    </div>
                </div>
            </div>
            <div className="popup__bottom">COPYRIGHT 2021 EGYPT777.COM ALL RIGHTS RESERVED</div>
        </div>
    )
    
    const gamePopup = (
        <div className="popup popup_fullscreen popup_game">
            { !size.mobile && <div className="popup-top">
                <div className="logo">
                    <img src={ Logo } alt="Logo" />
                </div>
                <div className="controls">
                    { 
                    //<Button type={["sound", "shadow", "sq", "sq-fixed"]} action={'close'} />
                    }
                    <Button type={["fullScreen", "shadow", "sq", "sq-fixed"]} action={'fullScreen'} />
                    <Button type={["close", "shadow", "sq", "sq-fixed"]} action={'closeGame'} />
                </div>
            </div> }
            <div className="game-code" dangerouslySetInnerHTML={{__html: game}}></div>
        </div>
    )

    const showContent = (type) => {
        //console.log('SWITCH', type);
        switch (type) {            
            case 'account':
                return account;
            case 'game':
                if (!size.mobile && (!size.landscape || launcher)) {
                    return false
                } else {
                    return gamePopup
                }
            case 'info':
                return <PopupInfo/>;
            case 'sms':
                return <PopupSms/>;
            case 'bonuses':
                return <PopupBonuses/>;
            default:
              return false;
        }
    }

    return (
        showContent(type)
    )    
}
