import { GET_AUTHORIZATION } from "../actions/actionTypes";

const initialState = {
  authorized: window.config.system.authorized,
};

const profileReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_AUTHORIZATION:
      return {
        ...state,
        authorized: action.payload,
      };
    default:
      return state;
  }
};

export default profileReducer;
