import {
  GET_AUTHORIZATION,
  GET_GAMELIST,
  GET_ACTIVE_GAMELIST,
  GET_JACKPOTS,
  GET_PROFILE_INFO,
  GET_ACTIVE_POPUP,
  GET_ACTIVE_AUTH,
  GET_CONFIG,
  GET_WORDS,
  GET_GAME_CODE,
  GET_WINDOW_SIZE,
  GET_FAVORITE,
  GET_HISTORY_GAMES,
  GET_AUDIO,
  GET_PRELOADER,
  GET_PROVIDERS_LIST,
  GET_LINK_BONUS
} from "./actionTypes";

export function getGameList(gameList) {
  return {
    type: GET_GAMELIST,
    payload: gameList,
  };
}

export function setActiveGameList(activeGameList) {
  return {
    type: GET_ACTIVE_GAMELIST,
    payload: activeGameList,
  };
}

export function getAuthorization(authorized) {
  return {
    type: GET_AUTHORIZATION,
    payload: authorized,
  };
}

export function getJackpots(jackpots) {
  return {
    type: GET_JACKPOTS,
    payload: jackpots,
  };
}

export function getProfileInfo(info) {
  return {
    type: GET_PROFILE_INFO,
    payload: info,
  };
}

export function getPopup(popup) {
  return {
    type: GET_ACTIVE_POPUP,
    payload: popup,
  };
}

export function getAuthForm(form) {
  return {
    type: GET_ACTIVE_AUTH,
    payload: form,
  };
}

export function getConfig(config) {
  return {
    type: GET_CONFIG,
    payload: config,
  };
}

export function getWords(words) {
  return {
    type: GET_WORDS,
    payload: words,
  };
}

export function getGameCode(code) {
  return {
    type: GET_GAME_CODE,
    payload: code,
  };
}

export function getWindowSize(size) {
  return {
    type: GET_WINDOW_SIZE,
    payload: size,
  };
}

export function getFavoritGames(favorite) {
  return {
    type: GET_FAVORITE,
    payload: favorite,
  };
}

export function getHistoryGames(history) {
  return {
    type: GET_HISTORY_GAMES,
    payload: history,
  };
}

export function getAudioPlayer(player) {
  window.localStorage.setItem('paused', player)
  return {
    type: GET_AUDIO,
    payload: player,
  };
}

export function setPreloader(preloader) {
  return {
    type: GET_PRELOADER,
    payload: preloader,
  };
}

export function getLinkBonus(id) {
  return {
    type: GET_LINK_BONUS,
    payload: id,
  };
}

export function setProvidersList(isOpen) {
  return {
    type: GET_PROVIDERS_LIST,
    payload: isOpen
  };
}
