import React, { useState } from 'react';
import './SigninForm.scss';

import { useSelector, useDispatch } from 'react-redux';
import { getAuthorization, setPreloader } from 'redux/actions';
import { Input } from 'components'
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import ReCAPTCHA from "react-google-recaptcha";
const $ = window.jQuery;

export const SigninForm = ({errorText}) => {

  const dispatch = useDispatch()

  const config = useSelector(state => state.config)
  const words = useSelector(state => state.words)

    const [formData, getFormData] = useState({})
    const [currencyList, setCurrencyList] = useState([])
    const [errorName, setErrorName] = useState(false)
    const [smsInput, setSmsInput] = useState(false)


    React.useEffect(() => {
        setCurrencyList(window.config.registrationData.currencies);
    }, []);


    const { email,
        // currencies,
        // languages,
        name,
        phone,
        // promo
    } = config.registrationData;

    const signin = (e) => {
      e.preventDefault()
      
      let queryObj = {
        cmd: "casinoRegister",
        domain: window.location.hostname,
        password: formData.password,
        // captcha: formData.captcha,
        terms: formData.terms,
      };
      for (let key in config.registrationData) {
        if (config.registrationData[key]) {
          queryObj[key] = formData[key]
        }        
      }   


      for (let key in queryObj) {
        if (!queryObj[key]) {
          setErrorName(key)
          return
        }        
      } 

      if (config.registrationData.phone) {
        queryObj.phone = (formData.dialCode + formData.phone).replace(/[^\d]/g, "");
        queryObj.RB = "phone";
      }
      //console.log('queryObj', queryObj);

    // if (config.promo) {
    //   queryObj.promo = localStorage.promo ? localStorage.promo : regPromo;
    //   }
      
    let response = window.core.query(queryObj);

    if (response.status === "success") {
        //console.log(window.core.getWord("successful_registration"));
        
        if (queryObj.hasOwnProperty("RB")) {
          setSmsInput(true)
        }
        else {
          dispatch( setPreloader(true) )
          setTimeout(function () {
              window.core.userLogin(queryObj.email, formData.password, 0, 0);
              if (window.config.system.authorized === true) {
                  //console.log("Условие 2-1 авторизовано");
                  dispatch( getAuthorization(window.config.system.authorized) )
                  //console.log('AUTHORIZED');
                  dispatch( setPreloader(false) )
                } 
          }, 500);
        }
      }
    else {
      //console.log('ERROR');
    }  
    setErrorName(false)
  }

  function confirmRegistration(e) {
    e.preventDefault();
    
    let casinoActivatequeryObj = {
      cmd: "casinoActivate",
      password: formData.password,
      domain: window.location.hostname,
      code: formData.sms,
      phone: (formData.dialCode + formData.phone).replace(/[^\d]/g, ""),
    };

    //console.log('casinoActivatequeryObj', casinoActivatequeryObj);


    if (!casinoActivatequeryObj.code) {
      setErrorName('sms code')
      return undefined;
    }
    
    let response = window.core.query(casinoActivatequeryObj);


    if (response.status === "success") {
      //console.log(window.core.getWord("successful_registration whith phone"));
      window.core.userLogin(casinoActivatequeryObj.phone, phone.password, 0, 0);
      setErrorName(false)
    } else {
      let error = response.error;
      //console.log(window.core.getWord("error") + ": " + error);
      setErrorName(false)
    }
  }
  
  
  function geo(success, failure) {
    $.get('https://ipinfo.io', function() {}, "jsonp").always(function(resp) {
      var countryCode = (resp && resp.country) ? resp.country : "";
      success(countryCode);
    });
  }
  function numberChange(isvalid, val) {
    getFormVal('phone',val)
  }
  function codeChange(isvalid, val) {
    getFormVal('dialCode',val.dialCode)
  }
  function getCaptcha(value) {
        getFormVal('captcha',value)
  }
  const getFormVal = (type, val) => {
      //console.log('type',type);
      //console.log('val',val);
        getFormData({
            ...formData,
            [type]: val    
        })
  }
  const currencySelect = () => {
        const currencyListELement = currencyList.map((element) => {
            return (
              <option key={`currency_${element}`} value={element}>
                {element}
              </option>
              );
        });
        return (
          <select
            className="auth__form-select"
            onChange={(e) => getFormVal('currencies',e.currentTarget.value)}
            // defaultValue={currencyList.length < 2?currencyList[0] : 0}
          >
            <option key="currency_placeholder" value={0}>
              Currency
            </option>
            {currencyListELement}
          </select>
        );
  };

    return (
      <React.Fragment>
        {!smsInput && 
          <form className="form" onSubmit={(e) => signin(e)}>            
            {email && <Input inputType={"mail"} valType={"email"} placeholder="E-mail" getFormVal={getFormVal} />}
            {name && <Input inputType={"text"} valType={"name"} placeholder="Name" getFormVal={getFormVal} />}
            {phone && 
            <IntlTelInput
                placeholder={'Phone number'}
                inputClassName="input"
                nationalMode={true}
                defaultCountry={'auto'}
                geoIpLookup={geo}
                separateDialCode={true}
                onPhoneNumberChange={numberChange}
                format={true}
                onSelectFlag={codeChange}
            />}<Input inputType={"password"} valType={"password"} placeholder="Password" getFormVal={getFormVal} />
            <div className="auth__form-row">
                <div className="auth__form-select-container">
                    {currencySelect()}
                </div>
                <div className="auth__checkbox">
                <input
                  type="checkbox"
                  name="terms"
                  id="terms"
                  onChange={(e) => getFormVal('terms',e.currentTarget.checked)}
                />
                <label className="checkbox" htmlFor="terms"></label>
                <div className="signInTerms">
                  <label htmlFor="terms">{words.server.i_turned_18}.</label>
                        <span className="termsHref"
                            // onClick={showTermsPopup}
                        >
                    {words.server.read_here}
                  </span>
                </div>
              </div>
            </div>
            <div
              className="capthca-container"
            >
            <ReCAPTCHA
                sitekey={window.config.captchaKey}
                onChange={getCaptcha}
            />
            </div>
            <button className="login-btn">Sign in</button>
            <div className="form__error">{errorText}</div>
        { errorName && <div className="form__error">Wrong data or empty {errorName} field</div>}
        </form>
        }
        {phone && smsInput &&
          <form className="form" onSubmit={(e) => confirmRegistration(e)}>
            <Input inputType={"text"} valType={"sms"} placeholder="sms code" getFormVal={getFormVal} />
            <button className="login-btn">Confirm</button>
            <div className="form__error">{errorText}</div>
            { errorName && <div className="form__error">Wrong data or empty {errorName} field</div>}
          </form>
        }
            
      </React.Fragment>
        
    )
}