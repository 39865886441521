import {
  GET_ACTIVE_GAMELIST,
  GET_ACTIVE_POPUP,
  GET_LINK_BONUS,
  GET_ACTIVE_AUTH,
  GET_WINDOW_SIZE,
  GET_PRELOADER,
  GET_PROVIDERS_LIST
} from "../actions/actionTypes";

const windowSize = {
  width: window.innerWidth,
  height: window.innerHeight,
};

const initialState = {
  activeGameList: 'all',
  linkBonus: false,
  activePopup: {
    active: false,
    name: "",
  },
  activeAuth: "login",
  size: {
    width: windowSize.width,
    height: windowSize.height,
    mobile: isMobile(windowSize),
    landscape: isLandscape(windowSize),
    sliderContainer: null,
  },
  preloader: true,
  providersList: false
};

function isMobile(a) {
  if (a.width < 992) {
    return true;
  } else {
    return false;
  }
}

function isLandscape(a) {
  if (a.width > a.height) {
    return true
  } else {
    return false
  }
}

const activeGameListReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ACTIVE_GAMELIST:
      return {
        ...state,
        activeGameList: action.payload,
      };
    case GET_ACTIVE_POPUP:
      return {
        ...state,
        activePopup: action.payload,
      };
    case GET_ACTIVE_AUTH:
      return {
        ...state,
        activeAuth: action.payload,
      };
    case GET_WINDOW_SIZE:
      return {
        ...state,
        size: {
          ...action.payload,
          mobile: isMobile(action.payload),
          landscape: isLandscape(action.payload)
        },
      };
    case GET_PRELOADER:
      return {
        ...state,
        preloader: action.payload,
      };
    case GET_PROVIDERS_LIST:
      return {
        ...state,
        providersList: action.payload,
      };
    case GET_LINK_BONUS:
      return {
        ...state,
        linkBonus: action.payload,
      };
    default:
      return state;
  }
};

export default activeGameListReducer;
