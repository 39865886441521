import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import './Bonus.scss'

export const Bonus = ({ title, text, images, id }) => {

	const linkBonus = useSelector(state => state.handling.linkBonus)
	const [active, setActive] = useState(false)
	const handleOpen = () => setActive(!active)
	const windowSize = useSelector(state => state.handling.size)
	const words = useSelector(state => state.words)

	useEffect(() => {
		linkBonus === id && setActive(true)
	  }, [linkBonus])

	return (
		<div className='bonus' data-active={ active }>
			{
				(windowSize.mobile && windowSize.landscape ) && 
					<img className='image swiper-lazy' data-src={images[1]} alt={ title }/>
			}

			{
				!(windowSize.mobile && windowSize.landscape) && 
					<img className='image swiper-lazy' data-src={images[0]} alt={ title }/>
			}
			<div className="loader">

			</div>
			<div className="wrapper">
				{ active && 
						<div className="info">
							<p className="title">{ title }</p>
							<p className="text">{ text }</p>
						</div>
				}
				<div className="get-info-button" onClick={ handleOpen }>{ !active ? words.server.INFO : words.server.back }</div>
			</div>
		</div>
	)
}