import React from 'react'

import { useSelector, useDispatch } from 'react-redux'
import { getPopup } from 'redux/actions'

import './Monitor.scss'

export const Monitor = ({ type, info, value }) => {

    const dispatch = useDispatch()
    const mobile = useSelector(state => state.handling.size.mobile)
    const words = useSelector(state => state.words)


    const onInfoBtnClick = (e) => {
        if (e.target.classList.contains('show')) {
            e.target.classList.remove('show')
            dispatch( getPopup({ active: false, name: '' }) )
        }
        else {
            e.target.classList.add('show')
            type === 'balance'  && dispatch( getPopup({ active: true, name: 'info' }) )
            type === 'bonus'  && dispatch( getPopup({ active: true, name: 'bonuses' }) )
        }
        
    }
    const handleClick = (e) => {
        if (type === 'balance') {
            mobile ? dispatch( getPopup({ active: true, name: 'account' }) ) : onInfoBtnClick(e)
        } else if (type === 'bonus'){
            mobile ? dispatch( getPopup({ active: true, name: 'bonuses' }) ) : onInfoBtnClick(e)
        }
    }

    const renderLabel = () => {
        if (type === 'balance') {
            return words.server.balance
        } else if (type === 'bonus'){
            return 'bonus'
        }
    } 

    return (
        <div className="numbers-block">
            <div className={`numbers__image numbers__image_${type}`}></div>
            <div className="numbers__desc">
                <div className="numbers__name">{ renderLabel() } 
                    {info && <div className="info" onClick={(e) => handleClick(e)}>{words.server.show_more}</div>}
                </div>
                <div className="numbers__value">{value}</div>
            </div>
        </div>
    )
}
