import React from 'react';
import './history.scss';

import { connect } from 'react-redux';



const History = ({ words, balanceInfo }) => {

    const [gameHistory, setGameHistory] = React.useState([]);
    const [gameHistoryText, setGameHistoryText] = React.useState("");


    const historyDates = {
        start: {
            date: '',
            getdate(e) {
                this.date = e.target.value;
            },
            time: '',
            getTime(e) {
                this.time = e.target.value;
            },
        },
        end: {
            date: '',
            getdate(e) {
                this.date = e.target.value;
            },
            time: '',
            getTime(e) {
                this.time = e.target.value;
            },
        },
    };
    
    function showGamesHistory() {
        //console.log('historyDates',historyDates);
        let response = window.core.query({
          cmd: "casinoGamesHistory",
          start: 
            historyDates.start.date +
            " " +
            historyDates.start.time,
          end:
            historyDates.end.date +
            " " +
            historyDates.start.time,
        });

        if (response.status === "success") {
          if (response.content.length > 0) {
              if (response.content) {
                //console.log('response.content',response.content)
              setGameHistory(response.content);
              setGameHistoryText("");
            }
          } else {
            setGameHistoryText("No games found for this time period.");
          }
        }
    }
    const gameTable = () => {
        const gameHistoryTable = gameHistory.map(
          ({ id, game_name, datetime_in, datetime_out }) => {
                return (
                    <div className="row" key={`${id} + ${datetime_out}`}>
                <div className="gameId">{id}</div>
                <div className="gameName">{game_name}</div>
                <div className="start">
                  <div className="date">{datetime_in}</div>
                </div>
                <div className="end">
                  <div className="date">{datetime_out}</div>
                </div>
              </div>
            );
          }
        );
        return gameHistoryTable;
      };

    return (
        <div className="account__tab account__tab-history">
          <form className="settings history">
            <div className="settings__block settings__block_w50">
              <label htmlFor="" className="title">
                {words.server.start}
              </label>
              <input
                className="date"
                type="date"
                id="date_start"
                onChange={(e)=>historyDates.start.getdate(e)}
              />
              <div className="arrow arrow_date"></div>
              <input
                className="time"
                type="time"
                id="time_start"
                onChange={(e)=>historyDates.start.getTime(e)}
              />
              <div className="arrow arrow_time"></div>
            </div>
            <div className="settings__block settings__block_w50">
              <label htmlFor="" className="title">
                {words.server.the_end}
              </label>
              <input
                className="date"
                type="date"
                id="date_end"
                onChange={(e)=>historyDates.end.getdate(e)}
              />
              <div className="arrow arrow_date"></div>
              <input
                className="time"
                type="time"
                id="time_end"
                onChange={(e)=>historyDates.end.getTime(e)}
              />
              <div className="arrow arrow_time"></div>
            </div>
            <div className="settings__block settings__block_nobg">
              <span className="show-history" type="submit" onClick={showGamesHistory}>
                Show
              </span>
            </div>
          </form>
          <div className="list__block">
            <div className="title">{words.server.games_history}</div>
            <div className="list__top">
              <div className="gameId">{words.server.id}</div>
              <div className="gameName">{words.server.fio}</div>
              <div className="start">{words.server.start}</div>
              <div className="end">{words.server.the_end}</div>
          </div>
          <div className="list__empty">{gameHistoryText}</div>          
            <div className="list">
              {gameTable()}
              {/* <div className="message error">{gameHistoryText}</div> */}
            </div>
          </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        words: state.words,        
    }
  }
  
  
export default connect(mapStateToProps)(History);