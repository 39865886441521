import React, { useState } from 'react'

import { Input } from 'components'
import { useSelector } from 'react-redux'

import './ForgotForm.scss'

export const ForgotForm = ({ errorText }) => {

  const configRecovery = useSelector(state => state.config.recoveryPassword)
  const [formData, getFormData] = useState({})
  const [recoverySuccess, setRecoverySuccess] = useState(false)

    const restore = (e) => {
      e.preventDefault();
      //console.log("RECOVERRRRRRRRRRR",formData);

      const queryObj = {
        cmd: "passwordRecovery",
        domain: window.location.hostname,
      };

      if (configRecovery === 'both') {
        queryObj.e_mail = formData.mail ? formData.mail : '';
        queryObj.phone = formData.tel ? formData.tel.replace(/[^\d]/g, "") : '';
      }
      else if (configRecovery === 'email') {
        queryObj.e_mail = formData.mail ? formData.mail : '';
      }
      else if (configRecovery === 'phone') {
        queryObj.phone = formData.tel ? formData.tel.replace(/[^\d]/g, "") : '';
      }
    
        let response = window.core.query(queryObj);
        // window.core.query(queryObj)
        if (response.status === "success") {
          setRecoverySuccess(true)
        } else {
          setRecoverySuccess(false)
        }
  }
  
    const getFormVal = (type, val) => {
        //console.log('FormData', formData);
        //console.log('from type', type);
        //console.log('from val', val);
        getFormData({
            ...formData,
            [type]: val    
        })
    }

    return (
        <form className="form" onSubmit={(e) => restore(e)}>
            {(configRecovery === 'both' || configRecovery === 'email') && <Input inputType={"mail"} valType={"mail"} placeholder="E-mail" getFormVal={getFormVal} />}
            <div className="or">or</div>
            {(configRecovery === 'both' || configRecovery === 'phone') && <Input inputType={"tel"} valType={"tel"} placeholder="Phone number" getFormVal={getFormVal}/>}
            <button className="login-btn">Restore</button>
        <div className={recoverySuccess ? "form__error green" : "form__error"}>{errorText}</div>
        </form>
    )
}