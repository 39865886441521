import {
  GET_GAMELIST,
  GET_FAVORITE,
  GET_HISTORY_GAMES,
} from "../actions/actionTypes";

const initialState = {
  providers: [],
  gameListAll: [],
  gamesTopId: [],
  gamesNewId: [],
  gamesAllId: [],
  gamesByProviders: {},
};

const separateGamesByProviders = (providers, gameList) => {
  let obj = {};
  providers.map((item) => {
    let newArr = [];
    for (let i = 0; i < gameList.length; i++) {
      if (gameList[i].label === item.label) {
        newArr.push(gameList[i]);
      }
    }
    obj[item.label] = newArr;
  });
  return obj;
};

const separateGamesById = (ids, gameList) => {
  let newArr = [];
  ids.map((item) => {
    for (let i = 0; i < gameList.length; i++) {
      if (gameList[i].id === item) {
        newArr.push(gameList[i]);
      }
    }
  });
  return newArr;
};

const separateGamesByFreespins = (gameList) => {
  let newArr = []
    for (let i = 0; i < gameList.length; i++) {
      gameList[i].fs && newArr.push(gameList[i])
    }
  return newArr
};

const findFavoriteGames = (gameList, storage) => {
  let newArr = [];
  if (storage && storage.length > 0) {
    storage.map((item) => {
      for (let i = 0; i < gameList.length; i++) {
        if (gameList[i].id === item) {
          newArr.push(gameList[i]);
        }
      }
    });
  }
  return newArr;
};

const findHistoryGames = (gameList, history) => {
  let newArr = [];
  if (history && history.length > 0) {
    history.map((item) => {
      for (let i = 0; i < gameList.length; i++) {
        if (gameList[i].name === item) {
          newArr.push(gameList[i]);
        }
      }
    });
  }
  return newArr;
};

const addAllProvider = providers => {
  let newProviders = providers
  newProviders.unshift({ name: 'ALL', label: 'all' })
  return newProviders
}

const gameListReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_GAMELIST:
      return {
        ...state,
        providers: addAllProvider(action.payload.gameLabels.slice(3)),
        gameListAll: action.payload.gameList,
        gamesTopId: action.payload.top.top,
        gamesNewId: action.payload.top.new,
        gamesAllId: action.payload.top.all,
        gamesByProviders: {
          ...separateGamesByProviders(
            action.payload.gameLabels.slice(3),
            action.payload.gameList
          ),
          new: separateGamesById(
            action.payload.top.new,
            action.payload.gameList
          ),
          top: separateGamesById(
            action.payload.top.top,
            action.payload.gameList
          ),
          freespins: separateGamesByFreespins(action.payload.gameList),
          all: action.payload.gameList,
        },
      };
    case GET_FAVORITE:
      return {
        ...state,
        gamesByProviders: {
          ...state.gamesByProviders,
          favorite: findFavoriteGames(
            action.payload.gameList,
            action.payload.storage
          ),
        },
      };
    case GET_HISTORY_GAMES:
      return {
        ...state,
        gamesByProviders: {
          ...state.gamesByProviders,
          last: findHistoryGames(
            action.payload.gameList,
            action.payload.history
          ),
        },
      };
    default:
      return state;
  }
};

export default gameListReducer;
