export const GET_GAMELIST = "GET_GAMELIST"
export const GET_ACTIVE_GAMELIST = "GET_ACTIVE_GAMELIST"
export const GET_AUTHORIZATION = "GET_AUTHORIZATION"
export const GET_JACKPOTS = "GET_JACKPOTS"
export const GET_PROFILE_INFO = "GET_PROFILE_INFO"
export const GET_ACTIVE_POPUP = "GET_ACTIVE_POPUP"
export const GET_ACTIVE_AUTH = "GET_ACTIVE_AUTH"
export const GET_CONFIG = "GET_CONFIG"
export const GET_WORDS = "GET_WORDS"
export const GET_GAME_CODE = "GET_GAME_CODE"
export const GET_WINDOW_SIZE = "GET_WINDOW_SIZE"
export const GET_FAVORITE = "GET_FAVORITE"
export const GET_HISTORY_GAMES = "GET_HISTORY_GAMES"
export const GET_WAGER_TIME = "GET_WAGER_TIME"
export const GET_AUDIO = "GET_AUDIO"
export const GET_PRELOADER = "GET_PRELOADER"
export const GET_PROVIDERS_LIST = "GET_PROVIDERS_LIST"
export const GET_LINK_BONUS = "GET_LINK_BONUS"
