import React from 'react'
import { useSelector } from 'react-redux'
import './Preloader.scss'

export const Preloader = () => {

	const showPreloader = useSelector(state => state.handling.preloader)

	return ( 
		<div className={ showPreloader ? "preloader" : "preloader hide"}>  
			<div className="loader">
				<div className="image">
					<div className="body"></div>
					<div className="wing wing_l"></div>
					<div className="wing wing_r"></div>
					<div className="shell shell_l"></div>
					<div className="shell shell_r"></div>
				</div>
			</div>
		</div>
	)
}