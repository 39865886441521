import React, { useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getAudioPlayer } from 'redux/actions'

export const Sound = () => {
	const audio = useRef(null)
	const paused = useSelector(state => state.audio.paused)
	const game = useSelector(state => state.gameCode)
	const dispatch = useDispatch()
			
	useEffect(() => {

		if (audio.current) {
			let promise = audio.current.play()

			if (promise !== undefined) {
				promise
					.then(_ => {
							(!paused && !game) ? audio.current.play() : audio.current.pause()
					})
					.catch(error => {
						dispatch(getAudioPlayer(true))
					})
			}
		}
	},[paused, game])

	const renderAudio = () => {
		if (!paused && !game) {
			return (
				<audio autoPlay={true} loop ref={audio}>
					<source src="./sound/sound.mp3" type="audio/mp3" />
				</audio>
			)
		} else {
			return false
		}
	}

	return (
		 renderAudio() 
	)
}