import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
// import SocialLogin from './../socialLogin'
import { Button, ForgotForm, SigninForm, LoginForm } from 'components'
import { setPreloader } from 'redux/actions'
import './Auth.scss'

import Logo from 'img/logo.png'


export const Auth = () => {

	const words = useSelector(state => state.words)
	const casino = useSelector(state => state.config.casino)
	const authFormType = useSelector(state => state.handling.activeAuth)

	const dispatch = useDispatch()
	const [errorText, setErrorText] = useState('')

	const formName = (authFormType) => {
		switch (authFormType) {
			case 'login':
				return {
					name: words.server.login,
					btnText: 'Sign in',
					link: 'signin'
				}
			case 'signin':
				return {
					name:'Sign in',
					btnText: 'Login',
					link: 'login'
				}
			case 'forgot':
				return {
					name:'Forgot password?',
					btnText: 'Login',
					link: 'login'
				}                            
			default:
				return {
					name:'Login',
					btnText: 'Login',
					link: 'signin'
				}
		}
	}

	useEffect(() => {
		window.core.functions.onQueryError = (data, response) => {
			if (data.cmd === "terminalLogin" || data.cmd === 'passwordRecovery' || data.cmd === 'casinoRegister' || data.cmd === 'casinoActivate') {
				setErrorText(response.error)
			}
		}
		setTimeout(() => dispatch( setPreloader(false) ), 2000)
	}, [])
    
	useEffect(() => setErrorText(''), [authFormType])

	return (
		<div className="auth">
			<div className="auth-content">

				<div className="auth__logo">
					<img src={ Logo } alt="Logo"/>
				</div>

				<div className="auth__form">

					<div className="auth__top">
						<div className="auth__name">{ formName(authFormType).name }</div>
						<div className="auth__buttons">
								<Button key="button_sound" type={["sound", "sq", "sq-fixed"]} action={'sound'} />
								<Button type={["lang", "sq", "sq-fixed"]} action={'lang'}/>
						</div>
					</div>

					{ authFormType === 'login' && <LoginForm errorText={errorText} />}
					{ (casino && authFormType === 'signin') && <SigninForm errorText={errorText} /> }
					{ (casino && authFormType === 'forgot') && <ForgotForm errorText={errorText} /> }
					{ /* <SocialLogin uloginId={"uloginFromLogin"} /> */ }
					{ casino && 
						<div className="auth__button-container">
							<Button
								action={ 'switchLogin' }
								type={ ["wide", "wide-fixed", 'text'] }
								content={ formName(authFormType) }
							/>
						</div>
					}
				</div>
			</div>
		</div>  
	)
}
