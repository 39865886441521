import React, { useEffect, useState } from 'react'
import { getPopup, getLinkBonus } from 'redux/actions'
import { connect, useSelector, useDispatch } from 'react-redux'
import './balance.scss'

const $ = window.jQuery;

const Balance = ({words, balanceInfo, casino, mobile}) => {
    
    const dispatch = useDispatch()
    const profileInfo = useSelector(state => state.profileInfo)
    const [bonuceText, setBonuceText] = useState("")

    useEffect(() => {

        window.core.elements.bonuses.push(".info-text")

        const updateBonuceTest = setInterval(function () {
          setBonuceText({
            cumulative: $('.word_cumulative_bonus_info').text(),
            cashback: $('.word_cashback_bonus_info').text(),
            wager: `${$('.word_wager_bonus').first().text()} - ${$('.word_wager_bonus_info').text()}`,
            chargeable: `${$('.word_chargeable_balance').first().text()} - ${$('.word_chargeable_balance_info').text()}`,
            hoursInfo: $('.word_happy_hours').text(),
            wagerInfo: $('.wager_status').text(),
            wheelInfo: $('.wheel_status').text(),
          })
        }, 500)

        return () => clearInterval(updateBonuceTest)
    
    }, [])

    const linkToBonus = label => {
        dispatch( getPopup({ active: false, name: '' }) )
        dispatch( getLinkBonus(label) )
    }
    
    return (
        <div className="account__tab account__tab-balance">
        {balanceInfo.name.length > 1 && <div className="row">
            <div className="title">{words.server.fio}</div>
            <div className="value">{balanceInfo.name}</div>
        </div>}
        <div className="row">
            <div className="title">{words.server.lo_gin}</div>
            <div className="value">{balanceInfo.login}</div>
            <div className="edit"></div>
        </div>
        <div className="row">
            <div className="title">{words.server.balance}</div>
            <div className="value">{balanceInfo.balance} { balanceInfo.currency}</div>
            <div className="edit"></div>
        </div>

        {
            (casino || !mobile) &&
            <>
            <div className="row">
            <div className="title">{words.server.chargeable_balance}</div>
            <div className="value">{balanceInfo.chargeable}</div>
            <div className="edit"></div>
        </div>
        <div className="row">
            <div className="title">{words.server.wager}</div>
            <div className="value">{balanceInfo.wagering}/{balanceInfo.wager}</div>
            <div className="edit"></div>
        </div>
        <div className="row">
            <div className="title">
            {words.server.сashback_bonus}
            <span className="red">*</span>
            </div>
            <div className="value">{balanceInfo.bonuce}</div>
            <div className="edit"></div>
        </div>
        {window.core.terminal.content.cumulative.enable && 
        <div className="row">
            <div className="title">
            {words.server.cumulative_bonus}
            <span className="red">*</span>
            </div>
            <div className="value">{balanceInfo.cumulativeValue}/{balanceInfo.cumulativeTotal}</div>
        </div>
        }
        {window.core.terminal.content.cumulative_wheel && 
            <div className="row">
            <div className="title">
                {words.server.wheel_of_fortune}
                <span className="red">*</span>
            </div>
            <div className="value">
                {balanceInfo.wheelValue}/{balanceInfo.wheelTotal}
            </div>
            <div className="edit"></div>
            </div>
        }
            </>
        }
        
        <div className="info-text"></div>
        <div className="information">

            {
                profileInfo.words.chargeable_balance && profileInfo.wager.chargeable &&
                    <div className="line">
                        <span className="title">{ profileInfo.words.chargeable_balance }: </span>
                        <span className="value">{ profileInfo.wager.chargeable }</span>
                    </div>
            }
        
            {
                profileInfo.words.wager_bonus && profileInfo.wager.wagering && profileInfo.wager.value  && 
                    <div className="line">
                        <span className="title">{ profileInfo.words.wager_bonus }: </span>
                        <span className="value">{ profileInfo.wager.wagering } / { profileInfo.wager.value }</span>
                    </div>
            }

            {
                profileInfo.words.cashback_bonus && profileInfo.cash_bonus &&
                    <div className="line">
                        <span className="title">{ profileInfo.words.cashback_bonus }: </span>
                        <span className="value">{ profileInfo.cash_bonus }</span>
                    </div>
            }

            {
                profileInfo.words.cumulative_bonus &&  profileInfo.cumulative.value && profileInfo.cumulative.take_after && profileInfo.cumulative.value && 
                <div className="line">
                    <span className="title">{ profileInfo.words.cumulative_bonus }: </span>
                    <span className="value">{ profileInfo.cumulative.value } / { profileInfo.cumulative.take_after }</span>

                    <progress max={ profileInfo.cumulative.take_after } value={ profileInfo.cumulative.value }></progress>
                </div>
            }

            {
                profileInfo.words.cumulative_wheel_bonus &&  profileInfo.cumulative_wheel.value && profileInfo.cumulative_wheel.take_after && profileInfo.cumulative_wheel.value && 
                <div className="line">
                    <span className="title">{ profileInfo.words.cumulative_wheel_bonus }: </span>
                    <span className="value">{ profileInfo.cumulative_wheel.value } / { profileInfo.cumulative_wheel.take_after }</span>

                    <progress max={ profileInfo.cumulative_wheel.take_after } value={ profileInfo.cumulative_wheel.value }></progress>
                </div>
            }

            <p>{ profileInfo.words.wager_status }</p>

            <p dangerouslySetInnerHTML={{ __html: profileInfo.words.wheel_status }} />

            <h4>{ profileInfo.words.information }</h4>
            {
                profileInfo.words.promocodes_bonus && profileInfo.words.promocodes_bonus_info &&
                    <div className="bonus-item">
                        <p className="title">{ profileInfo.words.promocodes_bonus }</p>
                        <p className="text" dangerouslySetInnerHTML={{ __html: profileInfo.words.promocodes_bonus_info  }} />

                        <form id="account-promo-form" className="account-promo-form">
                            <p id="promo_message" className="promo_message"></p>
                            <div className="account-field-wrap">
                                <input id="account-promocode" className="account-promocode" type="text" placeholder="Enter promo code" />
                            </div>
                            <button type="submit" className="account-submit-button promo-submit-btn">Send</button>
                        </form>

                    </div>
            }
            

            {
                profileInfo.words.chargeable_balance && profileInfo.words.chargeable_balance_info &&
                    <div className="bonus-item">
                        <p className="title">{ profileInfo.words.chargeable_balance }</p>
                        <p className="text" dangerouslySetInnerHTML={{ __html: profileInfo.words.chargeable_balance_info }} />
                    </div>
            }

            {
                profileInfo.words.wager_bonus && profileInfo.words.wager_bonus_info &&
                    <div className="bonus-item">
                        <p className="title link" onClick={() => linkToBonus('wager')}>{ profileInfo.words.wager_bonus }</p>
                        <p className="text" dangerouslySetInnerHTML={{ __html: profileInfo.words.wager_bonus_info }} />
                    </div>
            }

            {
                profileInfo.words.cashback_bonus && profileInfo.words.cashback_bonus_info &&
                    <div className="bonus-item">
                        <p className="title link" onClick={() => linkToBonus('cashback_bonus')}>{ profileInfo.words.cashback_bonus }</p>
                        <p className="text" dangerouslySetInnerHTML={{ __html: profileInfo.words.cashback_bonus_info }} />
                    </div>
            }

            {
                profileInfo.words.cumulative_bonus && profileInfo.words.cumulative_bonus_info &&
                    <div className="bonus-item">
                        <p className="title link" onClick={() => linkToBonus('accumulated_bonus')}>{ profileInfo.words.cumulative_bonus }</p>
                        <p className="text" dangerouslySetInnerHTML={{ __html: profileInfo.words.cumulative_bonus_info }} />
                    </div>
            }

            {
                profileInfo.words.cumulative_wheel_bonus && profileInfo.words.cumulative_wheel_info &&
                <div className="bonus-item">
                    <p className="title link" onClick={() => linkToBonus('wheel_of_freespins')}>{ profileInfo.words.cumulative_wheel_bonus }</p>
                    <p className="text" dangerouslySetInnerHTML={{ __html: profileInfo.words.cumulative_wheel_info }} />
                </div>
            }
        </div>
        { 
            //<div className="info-text"></div>
        }

        <div className="desktop-info">
        <div className="present">
                <img src="./images/present.png" alt="present" width="49" height="56"/>
                {bonuceText.wagerInfo}        
            </div>
            <div className="row_info">
                { bonuceText.hoursInfo}
                
                <br/>
                <br/>
                {bonuceText.wheelInfo}
                <br/>
                <br/>
                {bonuceText.chargeable}
                <br/>
                <br/>
                {bonuceText.wager}
            </div>
            <div className="notice">
                { bonuceText.cashback && 
                    <div className="left">
                        <div className="title">
                            {words.server.сashback_bonus}
                            <span className="red">*</span>
                        </div>
                        <div className="text">
                            {bonuceText.cashback}
                        </div>
                    </div>
                }
                <div className="separator"></div>
                { bonuceText.cumulative &&
                    <div className="right">
                        <div className="title">
                            {words.server.cumulative_bonus}
                            <span className="red">*</span>
                        </div>
                        <div className="text">
                        {bonuceText.cumulative}
                        </div>
                    </div>
                }
          
            </div>
        </div>
            
        
    </div>
    )
}

const mapStateToProps = (state) => {
    return {
        words: state.words,
        casino: state.config.casino,
        mobile: state.handling.size.mobile,
        balanceInfo: {
            login: state.profileInfo.login,
            name: state.profileInfo.name,
            balance: state.profileInfo.cash,
            chargeable: state.profileInfo.wager.chargeable,
            wager: state.profileInfo.wager.value,
            wagering: state.profileInfo.wager.wagering,
            wheelValue: state.profileInfo.cumulative_wheel.value,
            wheelTotal: state.profileInfo.cumulative_wheel.take_after,
            cumulativeValue: state.profileInfo.cumulative.value,
            cumulativeTotal: state.profileInfo.cumulative.take_after,
            bonuce: state.profileInfo.cash_bonus,
            currency: state.profileInfo.currency,
        }
    }
  }
  
  
export default connect(mapStateToProps)(Balance);