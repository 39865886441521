import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { News, Button, JackpotList, Monitor, Timer } from 'components'

import './Header.scss'

import Logo from 'img/logo.png'

export const Header = () => {
    
	const size = useSelector(state => state.handling.size)
	const profile = useSelector(state => state.profileInfo)
	const jackpotsShow = useSelector(state => state.config.jackpotsShow)
	const gameCode = useSelector(state => state.gameCode)

	const [wager, setWager] = useState(false)

	useEffect(() => {
		profile.wager && setWager(profile.wager)
	}, [profile])

	const checkWager = () => { if (wager) return <Timer /> }

	const checkLauncher = () => {
		if (jackpotsShow && jackpotsShow.launcher) {
			return true
		} else {
			return false
		}
	}
	
	const renderTimerOrControls = () => {
		if (size.mobile) {
			return checkWager()
		} else if (!size.landscape) {
			return checkWager()
		} else if (!checkLauncher()) {
			return checkWager()
		} else if (!gameCode) {
			return checkWager()
		} else {
			return (
				<div className="controls">
					<Button key="button_lang" type={["lang", "sq", "sq-fixed"]} action={'lang'} />
					<Button type={["fullScreen", "shadow", "sq", "sq-fixed"]} action={'fullScreen'} />
					<Button type={["close", "shadow", "sq", "sq-fixed"]} action={'closeGame'} />
				</div>
			)
		}
	}

	return (
		<header className="header">
			{ !size.mobile && !gameCode && <News /> }
			<div className="container container_flex container_between">
					<div className="header__logo">
							<img src={ Logo } alt="pharaon-logo" />
					</div>
					
					{ !size.mobile && size.landscape && 
						<div className="header__jackpots-container">
							<div className="header__jackpots">
								<JackpotList />
							</div>
						</div>
					}

					{ size.mobile && 
						<div className="header__balance">
							<Monitor key="balance-monitor" type='balance' info={true} value={profile.cash} />
							<Monitor key="nonuce-monitor" type='bonus' value={profile.cash_bonus} />
						</div>
					}

					{ renderTimerOrControls() }
			</div>
			{ !size.mobile && gameCode && <News /> }
		</header>
	)
}