import { GET_AUDIO } from "../actions/actionTypes";

const getSound = () => {
  window.localStorage.getItem('paused') === null && window.localStorage.setItem('paused', true)

  return window.localStorage.getItem('paused') === 'true' ? true : false
}

const initialState = {
  paused: getSound()
};

const audioPlayerReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_AUDIO:
      return { paused: action.payload }
    default:
      return state;
  }
};

export default audioPlayerReducer;
