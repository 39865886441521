import React from 'react'
import './FooterAnimation.scss'

export const FooterAnimation = () => (
    <div className="footer__animation">               
        <div className="block block_1"></div>
        <div className="block block_2"></div>
        <div className="block block_3"></div> 
    </div>
)
